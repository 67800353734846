import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><span style={{
        "fontWeight": "400"
      }}>{`What does a generation of Alexa-raised kids look like?`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  Conversational experiences have altered, and sometimes enhanced, the way
  consumers conduct transactions and interact with brands. Over the past 2
  years, developers and brands alike have dedicated time and resources to
  building conversational experiences on messaging platforms including Facebook,
  Slack, and Kik. During that time, technology giants have launched
  voice-powered devices with their respective assistants including Amazon’s Echo
  Dot, the Google Home, and Apple’s HomePod.
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  Last week, Dashbot hosted our 2nd annual chatbot and voice assistant
  conference, SuperBot 2018, in San Francisco at Bespoke Events. There were 2
  notable differences in this year’s conference compared to the last that are
  key indicators to how the conversational industry is changing: the audience
  and the content.
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  In 2017, the audience was comprised of startup founders, developers, and
  growth marketers. In 2018, there was an influx of big brands including Target,
  Kaiser Permanente, Procter &amp; Gamble, and Hulu. More importantly, last
  year’s speakers’ focused heavily on text-based conversational interfaces
  compared to this year’s lineup who concentrated on the promise of voice.
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`1. Practical Use Cases are Leading to Actual Revenue`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  Industry email marketing open rates have been [hovering around
  24%](https://www.smartinsights.com/email-marketing/email-communications-strategy/statistics-sources-for-email-marketing/).
  Lauren Kunze, CEO of [Pandorabots](https://www.pandorabots.com/), has produced
  chatbots that have resulted in an 80% open rate. For the first time ever,
  brands can connect with consumers directly in an interface they are already
  communicating on and because of this, advertising and customer support
  chatbots are currently the most profitable.
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  It’s easy to reference customer service as a sample use case when discussing
  chatbots, but which industry is leveraging them in a productive, cost-saving
  way? “Banks are actually market leaders for cognitive computing, which is one
  of the reasons why we chose to focus on that vertical,” remarked Dror Oren,
  Chief Product Officer and co-founder of [Kasisto](https://kasisto.com/). They
  are able to quickly serve up data and information to customers through
  conversational AI experiences that a traditional banking experience wouldn’t
  be able to provide, which led to greater productivity and better customer
  experiences.{" "}
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  While replacing traditional customer experiences might be the route for some
  verticals, a supplemental conversational experience can lead to higher return
  and an elevated user experience. [The Mars
  Agency](http://www.themarsagency.com/news-events/) launched an in-store,
  voice-powered assistant called Bottle Genius which helps customers make
  whiskey choices at NYC’s Bottlerocket Wine &amp; Spirit. They created an
  experience that draws the essential elements of retail: the power of variety
  and aided decision-making. For the month of February 2018, this enhanced voice
  experience resulted in YOY sales increase by 14% for the 120 whiskeys featured
  on the Bottle Genius shelf set.
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`2. Big Brands are Fighting to Be Heard`}</span></h2>
    <p>{`How do you make sure your voice is heard when you’re extending a household brand to conversational interfaces? Michael Francisco, Amazon’s head of Alexa Skills Kit partners, compared two specific skills — the Patron Skill and the Johnny Walker Skill, both intent on selling you more booze. Both have similar functionality in terms of offering history about the liquor and information about the brand. Johnny Walker has a tasting experience and Patron has a recipe component. Patron is much more formal and in line with a traditional brand, whereas Johnny Walker is casually playful and jokes around. The writing is what will ensure your brand principles shine through in the assistant’s voice.`}</p>
    <p>{`If you want to strengthen your voice beyond the default assistant voice, Amazon encourages companies, like Viacom, to use native-branded voices when building Alexa Skills for hallmark brands such as `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Comedy-Central/dp/B07BLNMQTZ"
      }}>{`Comedy Central`}</a>{`. Mark Mezrich, vice president of product management of emerging products at Viacom, commented on the resounding support his team received from Amazon when selecting Kyle Kinane as Comedy Central’s voice in place of Alexa’s. Kinane has played a huge part in bolstering and maintaining Comedy Central’s brand and tone as consumers discover comedy tracks.`}</p>
    <p>{`Summing up the branded voice discussion was Austin Arensberg, principal at early-stage investors `}<a parentName="p" {...{
        "href": "https://scrum.vc/"
      }}>{`Scrum Ventures`}</a>{`, who hit the nail on the head with his comment on the lack of attention to “auditory anchors.” As he put it, “Brands have made such great efforts to make sure that when people see golden arches, they automatically think of McDonald’s. But we have not done the same thing for voice.” Once people take the time to thoroughly think through the kind of voice and experience they want to translate onto these interfaces, customers will remember them.`}</p>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`3. Voice Technology is Changing Customer Experiences`}</span></h2>
    <p>{`Let’s make it clear: Chatbots are not disappearing. The chatbots that provide a delightful, productive customer experience are outshining chatbots that are simply trendy and entertaining. Last year, Alex Ferrara from `}<a parentName="p" {...{
        "href": "https://www.bvp.com/"
      }}>{`Bessemer Venture Partners`}</a>{` compared business-oriented chatbots to enterprise solutions because of their clear pathway to monetization, and we’re now seeing a lot more task-based chatbots.`}</p>
    <p>{`This year at SuperBot 2018, BVP’s Jenny Gao asked us to think about the implications of voice by raising the question, “What does a generation of Alexa-raised kids look like?” People didn’t take to voice interfaces quickly, but consider the evolution of human interaction with technology: Consumers are instantly disappointed when they can’t zoom with their index finger and thumb. Next up, a voice-first world can’t be far off.`}</p>
    <p>{`At CES this year, it wasn’t just The Alexa Show. If you really scrutinize the announcements, you’ll find that companies in the hardware, automotive, and home-security industries are enhancing their products with voice-controlled technology. In the big picture, it’s not a device war — it’s a battle to create the best consumer experience, and that experience will include voice.`}</p>
    <span style={{
      "fontWeight": "400"
    }}>
  Watch the full videos of
  [presentations](https://www.dashbot.io/2018/04/09/superbot-2018-speaker-presentations/)
  and [panel
  discussions](https://www.dashbot.io/2018/04/05/superbot-2018-panel-videos/)!
    </span>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot and voice specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      